.user-dashboard {
    display: flex;
    height: auto;
    background-color: #f2f2f2;
  }
  
  .sidebar {
    text-align: center;
    width: 18rem;
    height: auto!important;
    min-height: 100vh!important;
    background-color: #fff;
    color: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5)!important;
  }
  
  .sidebar ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .sidebar li {
    padding: 10px;
  }
  
  .sidebar a {
    color: #fff;
    text-decoration: none;
    background: #3498db;
  }
  
  .sidebar a:hover {
    color: #f2f2f2;
    background-color: #3498db;
  }
  
  .main-content {
    flex-grow: 1;
  }
  

  .blank-div{
    padding-left: 50px;
    padding-top: 20px;
    font-size: 1.2rem;
    color: #777;
  }

.firstMainContent{
  padding-left: 30px;
  padding-top: 20px;
  margin-bottom: 30px;
}

  input[type="file"] {
    margin-bottom: 10px;
    margin-right: 60px;
  }

  .uploadDocinput{

  }

  .fileName{
    font-weight: 600;
    font-size: 2rem;
    margin-top: 150px;
    padding-left: 30px;
  }

  .metaData, .docView{
margin-left: 30px;
margin-top: 60px;
  }

  .docView{
    margin-bottom: 30px;
  }

  .dgtProof{
    margin-top: 65px;
    margin-left: 45px;
  }

  .changeViewbtn{
    margin: 60px 30px 20px 150px;
  }
  .verifyBtn{
    margin: 60px 30px 20px 30px;
  }
  .sidebar button {
    padding: 10px 10px;
    width: 150px;
    background-color: #3498db;
    color: #fff;
    border: none;
    cursor: pointer;
  }
  
  .sidebar button:hover {
    background-color: #3498db;
  }
  .main-content .disabled{
    padding: 10px 20px;
    background-color: #807f7f;
    color: #fff;
    border: none;
    cursor: pointer;
  }
  .main-content .disabled:hover {
    background-color: #807f7f;
  }
  .main-content button{
    padding: 10px 20px;
    background-color: #3498db;
    color: #fff;
    border: none;
    cursor: pointer;
    border-radius: 10px;
  }
  .main-content button:hover {
    background-color: #3498db;
  }
  .createId-btn{
    margin: 30px;
  }
  
  h3 {
    margin-top: 50px;
    color: #3498db;
  }
  
  p {
    margin-bottom: 10px;
  }
  
  pre {
    background-color: #fff;
    padding: 30px;
    margin: 0 40px 30px 30px!important;
    border-radius: 4px;
    overflow-x: auto;
  }
  
  .DocumentPreview {
    margin-bottom: 20px;
  }
  
  /* Customize the colors as desired */
  

  .boxx1, .boxx2, .boxx3, .boxx4{
    padding: 15px;
    padding-left: 30px;
    margin: 10px 50px 30px 80px;
    border-radius: 15px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3)!important;
  }

  .boxx1{
    background: #6096ba;
  }
  .boxx1 p{
    font-weight: bold;
  }
  .boxx1 h2{
    font-weight: bold;
    color: #fff;
  }

  .boxx2{
    background: #788bff;
  }
  .boxx2 p{
    font-weight: bold;
  }
  .boxx2 h2{
    font-weight: bold;
    color: #fff;
  }

  .boxx3{
    background: #f1c453;
  }
  .boxx3 p{
    font-weight: bold;
  }
  .boxx3 h2{
    font-weight: bold;
    color: #fff;
  }

  .boxx4{
    background: #a3cef1;
  }
  .boxx4 p{
    font-weight: bold;
  }
  .boxx4 h2{
    font-weight: bold;
    color: #fff;
  }

  .profile-table {
    width: 90%;
    border-collapse: collapse;
    margin: 20px;
  }
  
  .profile-table th,
  .profile-table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  .profile-table th {
    background-color: #f2f2f2;
    font-weight: bold;
  }
  
  .profile-table tbody tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .profile-table tbody tr:hover {
    background-color: #f5f5f5;
  }
  

  .cert-boxx{
    margin: 50px;
  }
  .cert-boxx1{
    height: 230px;
    width: 100%;
    margin-left: 30px;
    border-radius: 15px;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.3)!important;
    text-align: center;
  }
  .cert-boxx1 h3{
    font-weight: bold;
    margin-bottom: 20px;
  }


  /* Style for the document popup */
.document-popup {
  position: fixed;
  padding: 30px;
  top: 0;
  left: 35%;
  width: auto;
  height: auto;/* Semi-transparent background */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5)!important;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* Ensure the popup is above other content */
}

/* Style for the popup content */
.document-popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  max-width: 90%;
  max-height: 90%;
  overflow: auto; /* Add scroll bar if content exceeds max height */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.document-popup h3{
  margin: 0!important;
  padding-bottom: 10px!important;
}
/* Style for the document viewer within the popup */
/* .document-popup-content .document-viewer {
  width: 60%!important;
  height: 60%!important;
} */


.editProfile{
}
.editProfile img{
  border-radius: 10px;
  margin: 30px;
  background: #fff;
  padding: 10px;
  height: 120px;
  width: 120px;
}


.cert-boxx{
  margin: 50px;
}
.cert-boxx1{
  height: 200px;
  width: 100%;
  margin-left: 30px;
  margin-top: 30px;
  border-radius: 15px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3)!important;
  background: #fff;
}
.cert-boxx1 h3{
  font-weight: bold;
  font-size: 1.3rem;
}

.dropBtn{
  width: 95%;
  background: #fff!important;
  padding: 10px!important;
  color: #000!important;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
}
.dropdown-menu{
  width: 95%;
}